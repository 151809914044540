import { casting2Number } from '../../../../lib/Utils';

TaxesClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$http',
  'notifications',
  'serverAddress',
  'TaxesConstants',
  'DocumentDownloadFactory',
  '$timeout',
  'DialogFactory',
  'fileUploaderGenerator',
  '$log'
];
export default function TaxesClaimDetailCtrl(
  $scope,
  $controller,
  $http,
  notifications,
  serverAddress,
  TaxesConstants,
  DocumentDownloadFactory,
  $timeout,
  DialogFactory,
  fileUploaderGenerator,
  $log
) {
  const vm = this;

  const INITIAL_STATE = {
    claim: {
      id: '',
      state: {},
      client: {
        user: {
          is_active: true
        }
      },
      team_city: '',
      city: '',
      years: 0,
      date_paid: '',

      presumed_resolution: '',
      tax_rate: '',
      date_interests_until: '',

      compensation: '',
      amount_paid: '',
      interests: '',
      claim_amount: '',
      liquidation_tax_rate: '',
      rateable_value: '',
      tax_base: '',
      tax_base_reclamador: '',
      claim_amount_reclamador: '',
      purchase_price: '',
      transmission_price: '',
      obtained_amount: '' // TODO: back-end must return number
    },

    capitalGain: {
      town_purchase_price_nominal: null,
      town_purchase_price_real: null,
      town_transmission_price_nominal: null,
      town_transmission_price_real: null,

      province_purchase_price_nominal: null,
      province_purchase_price_real: null,
      province_transmission_price_nominal: null,
      province_transmission_price_real: null,

      purchase_price: null,
      deed_purchase_price_real: null,
      transmission_price: null,
      deed_transmission_price_real: null,

      ipc: null
    },
    capitalGainTips: {
      municipal_nominal_loss: null,
      municipal_real_loss: null,
      province_nominal_loss: null,
      province_real_loss: null,
      deed_nominal_loss: null,
      deed_real_loss: null
    },
    actions: {
      showNewCaseModal
    }
  };
  const METHODS = {
    postRefreshClaimCB,

    forceState,
    downloadAllTaxesDocuments,
    calculateCompensation,
    updateMunicipalGainCompensation,
    showDocumentMergingModal,
    showDocumentSlicingModal,
    showDocumentCopyModal,
    updateMunicipalTaxDetails,
    calculateTaxDetails,
    showCalculationButton,
    calculateCapitalGain,
    getCapitalGainTip,
    checkVisualCapitalGainTips
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, TaxesConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function (item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id,
          segment: vm.MODEL_PROVIDER
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>
  function postRefreshClaimCB() {
    // Getting data for capital gain calculator
    getCapitalGainData();
    vm.getDocumentTypes();
  }

  /* Plusvalia */
  function getCapitalGainTip(prop) {
    return vm.capitalGainTips[prop] ? 'pérdidas' : 'ganancias';
  }

  function getCapitalGainData() {
    const propsCG = Object.keys(vm.capitalGain);
    let empty = false;
    propsCG.forEach(p => {
      if (vm.claim.hasOwnProperty(p) && vm.claim[p] !== null) {
        vm.capitalGain[p] = +vm.claim[p];
      } else {
        vm.capitalGain[p] = null;
        empty = true;
      }
    });
    if (!empty) {
      $timeout(() => checkVisualCapitalGainTips());
    }
  }

  function checkVisualCapitalGainTips() {
    vm.capitalGainTips.municipal_nominal_loss =
      vm.capitalGain.town_purchase_price_nominal > vm.capitalGain.town_transmission_price_nominal;
    vm.capitalGainTips.municipal_real_loss =
      vm.capitalGain.town_purchase_price_real > vm.capitalGain.town_transmission_price_real;
    vm.capitalGainTips.province_nominal_loss =
      vm.capitalGain.province_purchase_price_nominal >
      vm.capitalGain.province_transmission_price_nominal;
    vm.capitalGainTips.province_real_loss =
      vm.capitalGain.province_purchase_price_real > vm.capitalGain.province_transmission_price_real;
    vm.capitalGainTips.deed_nominal_loss =
      vm.capitalGain.purchase_price > vm.capitalGain.transmission_price;
    vm.capitalGainTips.deed_real_loss =
      vm.capitalGain.deed_purchase_price_real > vm.capitalGain.deed_transmission_price_real;
  }

  function calculateCapitalGain() {
    const capitalGainParsed = casting2Number(vm.capitalGain);
    notifications.addCurrentView(
      'info',
      'Vamos a proceder a realizar el cálculo de ganancias vs perdidas'
    );

    $http
      .post(
        `${serverAddress.getBaseUrl()}impuestos/${vm.claim.id}/calculate-municipaltax-loss-gain/`,
        capitalGainParsed
      )
      .then(() => {
        /*
          Response
          town_purchase_price_real,
          town_transmission_price_real,
          province_purchase_price_real,
          province_transmission_price_real,
          deed_purchase_price_real,
          deed_transmission_price_real,
          deed_purchase_price_real
        */
        vm.claimDetail.update('Cálculo de ganancias vs perdidas completado');
      })
      .catch(error => {
        $log.error(error);
        notifications.addCurrentView('error', error.data);
      });
  }

  function forceState() {
    if (vm.claim.team_city == true) {
      vm.claim.state.name = 'REA en redacción';
    } else if (vm.claim.team_city == false) {
      vm.claim.state.name = 'Recurso de reposición en redacción';
    }
  }

  function downloadAllTaxesDocuments() {
    DocumentDownloadFactory.downloadAllTaxesDocuments(vm.claim);
  }

  function calculateCompensation() {
    if (vm.claim !== null) {
      notifications.addCurrentView(
        'info',
        'Realizando cálculos. Por favor, espera. Ya casi lo tenemos...'
      );
      vm.updateMunicipalGainCompensation();
    }
  }
  function calculateTaxDetails() {
    if (vm.claim !== null) {
      vm.updateMunicipalTaxDetails();
    }
  }

  function updateMunicipalGainCompensation() {
    var data = {
      tax_rate: vm.claim.tax_rate,
      city: vm.claim.city,
      years: vm.claim.years,
      amount_paid: vm.claim.amount_paid,
      date_paid: vm.claim.date_paid,
      date_interests_until: vm.claim.date_interests_until,
      claim_amount: vm.claim.claim_amount
    };
    $http({
      url:
        serverAddress.getBaseUrl() +
        'impuestos/' +
        vm.claim.id +
        '/calculate-municipalgain-compensation/',
      method: 'POST',
      data: data
    })
      .then(function () {
        notifications.clear();
        notifications.addCurrentView('success', 'Indemnización calculada');
        vm.claimDetail.update();
      })
      .catch(function (response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function updateMunicipalTaxDetails() {
    var data = {
      tax_rate: vm.claim.tax_rate,
      amount_paid: vm.claim.amount_paid,
      liquidation_tax_rate: vm.claim.liquidation_tax_rate,
      claim_amount: vm.claim.claim_amount
    };
    $http({
      url:
        serverAddress.getBaseUrl() +
        'impuestos/' +
        vm.claim.id +
        '/calculate-municipaltax-details/',
      method: 'POST',
      data: data
    })
      .then(function () {
        notifications.clear();
        vm.claimDetail.update();
      })
      .catch(function (response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: 'TA'
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function () {
        var successMsg = 'Unión de documentos solicitada. Cuando termine recibirás un email.';
        vm.claimDetail.update(successMsg);
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: 'TA'
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function () {
      var successMsg = 'Separación de documento solicitada. Cuando termine recibirás un email.';
      vm.claimDetail.update(successMsg);
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var multipleSourceSelectionEnabled = false;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: prefix
    };
    var copy_type = type;
    var claimTypes = vm.claimTypes;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function () {
        vm.claimDetail.update();
      }
    );
  }

  function showCalculationButton() {
    if (vm.claim && vm.claim.type) {
      return (
        ((vm.claim.type.id === 45 && vm.claim.amount_paid) ||
          (vm.claim.type.id === 44 &&
            ((vm.claim.city && vm.claim.years) || vm.claim.tax_rate) &&
            vm.claim.amount_paid)) &&
        vm.claim.date_paid
      );
    }
    return false;
  }

  /**
   *
   * @param {*} showNewCaseModal
   */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
