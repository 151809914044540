import _map from 'lodash/map';

select2Factory.$inject = ['serverAddress'];

export default function select2Factory(serverAddress) {
  var selectAgencies = buildSelect2({
    placeholder: 'Seleccione agencia',
    inputTooShortSubject: 'de la agencia',
    urlPath: 'client/agencies/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    }
  });

  var selectCampaign = buildSelect2({
    placeholder: 'Seleccione campaña',
    inputTooShortSubject: 'de la agencia',
    urlPath: 'campaigns/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    }
  });

  var selectAirlines = buildSelect2({
    placeholder: 'Seleccione aerolínea',
    inputTooShortSubject: 'de la aerolínea',
    urlPath: 'airdata/airlines_crm/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    },
    initSelection: function() {}
  });

  var selectSalesman = buildSelect2({
    placeholder: 'Seleccione el comercial',
    inputTooShortSubject: 'de la aerolínea',
    urlPath: 'salesman/',
    resultsMapFunction: function(e) {
      return { id: e.id, username: e.username };
    },
    formatResultFunction: function(e) {
      return e.username;
    },
    formatSelectionFunction: function(e) {
      return e.username;
    },
    initSelection: function() {}
  });

  var selectAirport = buildSelect2({
    inputTooShortSubject: 'de la aerolínea',
    urlPath: 'airdata/airports/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name, city_es: e.city_es, iata_code: e.iata_code };
    },
    formatResultFunction: function(e) {
      return e.city_es + ' (' + e.iata_code + ') - ' + e.name;
    },
    formatSelectionFunction: function(e) {
      return e.city_es + ' (' + e.iata_code + ') - ' + e.name;
    }
  });

  var selectCourts = buildSelect2({
    placeholder: 'Selecciona Juzgado',
    inputTooShortSubject: 'del juzgado',
    urlPath: 'courts/',
    resultsMapFunction: function(e) {
      const type = e.court_type || 'Juzgado';
      const number = e.number && e.number !== 0 ? (' nº ' + e.number) : '';
      return { id: e.id, name: e.city + ' (' + type + number + ')' };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    },
    initSelection: function() {}
  });

  var selectNotary = buildSelect2({
    placeholder: 'Seleccione un notario',
    inputTooShortSubject: 'del notario',
    urlPath: 'notaries/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name, province: e.province, town: e.town };
    },
    formatResultFunction: function(e) {
      if (e.province === null) {
        return e.name;
      }
      return e.town + ' (' + e.province + ') - ' + e.name;
    },
    formatSelectionFunction: function(e) {
      if (e.province === null) {
        return e.name;
      }
      return e.town + ' (' + e.province + ') - ' + e.name;
    }
  });

  var changeBank = buildSelect2({
    placeholder: 'Seleccione banco',
    inputTooShortSubject: 'del nombre del banco',
    urlPath: 'banco/banks/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    },
    initSelection: function() {}
  });

  var changeIssuingBank = buildSelect2({
    placeholder: 'Seleccione banco',
    inputTooShortSubject: 'del nombre del banco',
    urlPath: 'banco/issuing_bank/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    },
    initSelection: function() {}
  });

  var selectAgainst = buildSelect2({
    placeholder: 'Seleccione compañia',
    inputTooShortSubject: 'de la compañia',
    urlPath: 'common/against/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    }
  });

  var selectCity = buildSelect2({
    placeholder: 'Seleccione ciudad/municipio',
    inputTooShortSubject: 'del municpio',
    urlPath: 'geodata/city/',
    resultsMapFunction: function(e) {
      return { id: e.id, name: e.name };
    },
    formatResultFunction: function(e) {
      return e.name;
    },
    formatSelectionFunction: function(e) {
      return e.name;
    }
  });

  return {
    selectAgencies,
    selectCampaign,
    selectAirlines,
    selectSalesman,
    selectAirport,
    selectCourts,
    selectNotary,
    changeBank,
    selectAgainst,
    selectCity,
    changeIssuingBank
  };

  /* Private functions */
  function buildSelect2(inputData) {
    var base = {
      minimumInputLength: inputData['minimumInputLength'] || 3,
      formatInputTooShort: function(input, min) {
        var n = min - input.length;
        return (
          'Por favor, introduce ' +
          n +
          ' car' +
          (n === 1 ? 'á' : 'a') +
          'cter' +
          (n === 1 ? '' : 'es') +
          ' ' +
          inputData['inputTooShortSubject']
        );
      },
      ajax: {
        url: serverAddress.getDocumentUrl() + inputData['urlPath'],
        port: inputData['port'] || '8000',
        dataType: inputData['dataType'] || 'json',
        data: function(term) {
          return {
            q: term
          };
        },
        results: function(data) {
          var result = _map(data, inputData['resultsMapFunction']);
          return { results: result };
        }
      },
      formatResult: inputData['formatResultFunction'],
      formatSelection: inputData['formatSelectionFunction']
    };

    // First-level keys that are optional in select2 structures
    var OPTIONAL_KEYS = ['width', 'placeholder', 'initSelection'];
    OPTIONAL_KEYS.forEach(function(key) {
      if (inputData[key]) {
        base[key] = inputData[key];
      }
    });

    return base;
  }
}
