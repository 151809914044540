import angular from 'angular';
import { removeSpacesPhone } from '../../../../lib/Utils';
import ClaimDetailProductCtrl from './claim-detail-product/ClaimDetailProductCtrl';

import './client-answers';

angular
  .module('reclamador.components.claim.info', ['reclamador.components.claim.info.detail'])
  /**
   * common claim information
   */
  .component('claimBaseInfo', {
    bindings: {
      claim: '<',
      sources: '<',
      claimTypes: '<',
      finishClaim: '<',
      actions: '<'
    },
    controllerAs: 'vm',
    template: require('./claim-base-info.html')
  })
  /**
   * Basic client information.
   */
  .component('clientSummary', {
    bindings: {
      client: '<',
      comment: '<'
    },
    controller: function() {
      this.removeSpacesPhone = removeSpacesPhone;
    },
    controllerAs: 'vm',
    template: require('./client-summary.html')
  })
  .component('prepaymentSummary', {
    bindings: {
      claim: '<',
      paymentType: '<',
      paymentStatus: '<'
    },
    controllerAs: 'vm',
    template: require('./prepayment-summary.html')
  })
  /**
   * Unpaid information
   */
  .component('unpaidSummary', {
    bindings: {
      unpaidFile: '<'
    },
    controllerAs: 'vm',
    template: require('./unpaid-summary.html')
  })
  /**
   * Common claim header.
   */
  .component('claimHeader', {
    bindings: {
      claim: '<',
      prefix: '<'
    },
    controllerAs: 'vm',
    template: require('./claim-header.html')
  })
  /**
   * Simple legalfile component.
   */
  .component('legalfileSummary', {
    bindings: {
      legalfile: '<',
      generateLegalfile: '&'
    },
    controllerAs: 'vm',
    template: require('./legalfile-summary.html')
  })
  /**
   * Editable legalfile component.
   * Only works in BA and AC and is full of shit
   * TODO: full review
   */
  .component('legalfileEditableSummary', {
    bindings: {
      legalfile: '<',
      generateLegalfile: '&',
      courtlawyers: '<',
      procuradores: '<',
      trialcities: '<',
      selectCourts: '<'
    },
    controllerAs: 'vm',
    template: require('./legalfile-editable-summary.html')
  })

  /**
   * Detail view components
   */
  .controller('ClaimDetailProductCtrl', ClaimDetailProductCtrl)
  .component('claimDetailProduct', {
    bindings: {
      details: '=',
      data: '='
    },
    controller: ClaimDetailProductCtrl,
    controllerAs: 'vm',
    template: require('./claim-detail-product/claim-detail-product.html')
  });
