import angular from 'angular';

import NotificationsFactory from './notifications.factory';
import serverAddress from './server-address.factory';
import Lawyer from './lawyer.factory';
import SalesClaimState from './sales-claim-state.factory';
import dataLoader from './data-loader.factory';
import ClaimState from './claim-state.factory';
import UnpaidState from './unpaid-state.factory';
import constantsFactory from './constants.factory';
import select2Factory from './select2.factory';
import ClaimType from './claim-type.factory';
import ClaimPhases from './claim-phases.factory';
import IncentiveState from './incentive-state.factory';
import ClaimSource from './claim-source.factory';
import CourtLawyer from './court-lawyer.factory';
import ExternalCourtLawyer from './external-court-lawyer.factory';
import LawyerStatesCount from './lawyers-states-count.factory';
import Province from './province.factory';
import GeoProvince from './geoprovince.factory';
import GeoRegion from './georegion.factory';
import LegalExpert from './legalexpert.factory';
import OtherPartLawyer from './other-part-lawyer.factory';
import Court from './court.factory';
import Procurador from './procurador.factory';
import ClaimAgency from './claim-agency.factory';
import ClaimConsolidator from './claim-consolidator.factory';
import Segment from './segment.factory';
import SegmentLawyer from './segment-lawyer.factory';
import Verticals from './verticals.factory';
import LawyersList from './lawyers-list.factory';
import Agency from './agency.factory';
import Documents from './documents.factory';
import DocumentType from './document-type.factory';
import DocxTemplates from './docx-documents.factory';
import DocTemplateGroup from './doc-template-group.factory';
import FlightDocuments from './flight-documents.factory';
import ClaimsLawyerPanelData from './claims-lawyer-panel-data.factory';
import Affiliate from './affiliate.factory';
import UtilsFactory from './utils.factory';
import CostTypes from './cost-types.factory';
import StatesWithPreviewEmail from './states-with-preview-email.factory';
import TrialCity from './trialCity.factory';
import InvestorPortfolio from './investorportfolio.factory';
import BoughtPortfolio from './boughtportfolio.factory';

angular.module('reclamador.factories', ['ngResource'])
  // services
  .factory('UtilsFactory', UtilsFactory)
  .factory('notifications', NotificationsFactory)
  .factory('select2Factory', select2Factory)
  .factory('constantsFactory', constantsFactory)
  .factory('serverAddress', serverAddress)
  .factory('dataLoader', dataLoader)
  .factory('ClaimsLawyerPanelData', ClaimsLawyerPanelData)

  // data sources
  .factory('Segment', Segment)
  .factory('Verticals', Verticals)

  .factory('ClaimType', ClaimType)
  .factory('ClaimPhases', ClaimPhases)
  .factory('ClaimState', ClaimState)
  .factory('UnpaidState', UnpaidState)
  .factory('ClaimAgency', ClaimAgency)
  .factory('ClaimConsolidator', ClaimConsolidator)
  .factory('ClaimSource', ClaimSource)
  .factory('SalesClaimState', SalesClaimState)
  .factory('CostTypes', CostTypes)
  .factory('IncentiveState', IncentiveState)

  .factory('Documents', Documents)
  .factory('DocumentType', DocumentType)
  .factory('DocxTemplates', DocxTemplates)
  .factory('DocTemplateGroup', DocTemplateGroup)

  .factory('Lawyer', Lawyer)
  .factory('LawyersList', LawyersList)
  .factory('LegalExpert', LegalExpert)
  .factory('CourtLawyer', CourtLawyer)
  .factory('ExternalCourtLawyer', ExternalCourtLawyer)
  .factory('LawyerStatesCount', LawyerStatesCount)
  .factory('OtherPartLawyer', OtherPartLawyer)

  .factory('SegmentLawyer', SegmentLawyer)
  .factory('Procurador', Procurador)

  .factory('Agency', Agency)
  .factory('Affiliate', Affiliate)

  .factory('Court', Court)
  .factory('Province', Province)
  .factory('GeoProvince', GeoProvince)
  .factory('GeoRegion', GeoRegion)
  .factory('TrialCity', TrialCity)

  .factory('FlightDocuments', FlightDocuments)
  .factory('StatesWithPreviewEmail', StatesWithPreviewEmail)

  .factory('InvestorPortfolio', InvestorPortfolio)
  .factory('BoughtPortfolio', BoughtPortfolio);
