const SEGMENT_ID = 5,
  CLAIM_TYPE = 'so',
  MODEL_PROVIDER = 'SOClaim';

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'SO',
  ALIAS: 'so',
  CLAIM_TYPE,
  VERBOSE_NAME: 'Reclamación por segunda oportunidad',
  permissions: {
    downloadCSVPermission: 'segment-perobject-SO-crm_download_csv'
  },
  CLAIM_DICTIONARIES: {
    users: 'solawyers',
    salesman: 'sosalesmen',
    sources: 'claimsources',
    claimTypes: {
      entity: 'claimtypes',
      data: { type: CLAIM_TYPE }
    },
    courtlawyers: 'courtlawyers',
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    procuradores: 'procuradores',
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    debttypes: 'debttypes',
    liqtypes: 'liqtypes',
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: MODEL_PROVIDER }
    },
    trialcities: 'trialcities'
  },

  EXISTING_CLAIM: {
    0: 'No, no he puesto ninguna reclamación',
    1: 'Sí puse un  a reclamación pero no tengo un número',
    2: 'Sí, la puse y tengo un número de reclamación'
  },
  USER_TYPE_CHOICES: {
    P: 'Particular',
    A: 'Autónomo',
    E: 'Empresa'
  },
  CAUSE_CHOICES: {
    0: 'Facturas y cobros',
    1: 'Alta o baja de un servicio',
    2: 'Portabilidad',
    3: 'Velocidad de internet baja',
    4: 'Corte del servicio',
    5: 'Problemas con mi terminal',
    6: 'Servicios premium',
    7: 'Otros'
  },
  FC_PROBLEM_CHOICES: {
    0: 'Me han cobrado de más en mi/s última/s factura/s',
    1: 'La operadora me obliga a pagar una penalización por no cumplir la permanencia',
    2: 'He roto mi compromiso de permanencia y ahora la operadora me cobra un precio de terminal más alto de lo acordado.',
    3: 'Compré un terminal y la operadora me está cobrando más de lo pactado en cada plazo'
  },
  AB_PROBLEM_CHOICES: {
    0: 'La operadora no me permite darme de baja',
    1: 'Me han dado de baja sin solicitarselo',
    2: 'La operadora se niega a tramitar mi alta',
    3: 'Me han dado de alta sin solicitarselo',
    4: 'Me han dado de alta en un servicio de mensajes que yo no solicité',
    5: 'El operador del que me quiero ir no permite al nuevo que haga la portabilidad'
  },
  SWITCH_PROBLEM_CHOICES: {
    0: 'No me tramitan la portabilidad',
    1: 'Me han hecho una portabilidad que no solicité'
  },
  TERMINAL_PROBLEM_CHOICES: {
    0: 'Tengo una avería en el terminal y la operadora se niega a arreglarlo',
    1: 'El terminal que compré me ha llegado roto',
    2: 'El terminal que compré no es el que solicité',
    3: 'Compré un terminal y la operadora me está cobrando más de lo pactado en cada plazo',
    4: 'He roto mi compromiso de permanencia y ahora la operadora me cobra un precio de terminal más alto de lo acordado'
  },
  PREMIUM_PROBLEM_CHOICES: {
    0: 'Me han dado de alta en un servicio de mensajes que yo no solicité',
    1: 'Me han cobrado de más por la contratación de servicios por sms',
    2: 'Me han cobrado de más en un llamada que realicé',
    3: 'Me han cobrado llamadas que no realicé'
  },
  YES_NO_ANSWERS: {
    0: 'No',
    1: 'SI'
  },
  EPI_TYPE: {
    0: 'Fácil',
    1: 'Difícil'
  },
  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/so/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/so/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/so/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: '/partials/common/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true, hidden: true },
    { headerName: 'Provincia cliente', fieldName: 'client_province', sortable: true, hidden: true },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Fecha finalizada', fieldName: 'date_finished', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Fuente', fieldName: 'source_name', sortable: true },
    { headerName: 'Cómo nos conoció', fieldName: 'how_met_us', sortable: true },
    { headerName: 'Fuente invitador', fieldName: 'invited', sortable: true, hidden: true },
    { headerName: 'Campaña', fieldName: 'campaign_name', sortable: true, hidden: true },
    { headerName: 'SEM', fieldName: 'has_sem_gclid', sortable: true, hidden: true },
    { headerName: 'SEM (Valor)', fieldName: 'sem_gclid', sortable: true, hidden: true },
    { headerName: 'Network', fieldName: 'sem_network', sortable: true, hidden: true },
    { headerName: 'Placement', fieldName: 'sem_placement', sortable: true, hidden: true },
    { headerName: 'Campaign', fieldName: 'sem_campaign', sortable: true, hidden: true },
    { headerName: 'Ultimo cambio estado', fieldName: 'laststatechange', sortable: true, hidden: true },
    { headerName: 'Usuario cambio estado', fieldName: 'laststatechangeuser', sortable: true, hidden: true },
    { headerName: 'Comentario cambio estado', fieldName: 'laststatecomment', sortable: true, hidden: true },
    { headerName: 'Ultimo cambio estado comercial', fieldName: 'lastsalesstatechange', sortable: true, hidden: true },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    { headerName: 'Último comentario', fieldName: 'last_comment', sortable: true, hidden: true },
    { headerName: 'Usuario últ. comentario', fieldName: 'last_comment_user', sortable: true, hidden: true },
    { headerName: 'Fecha últ. comentario', fieldName: 'last_comment_date', sortable: true, hidden: true },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    { headerName: 'Numero documentos', fieldName: 'num_docs', sortable: true, hidden: true },
    { headerName: '', fieldName: null, sortable: true, alwaysVisible: true },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Cedido a',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {}
};
